import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { styled } from "styled-components";
import { ClipLoader } from "react-spinners";

const Home = lazy(() => import("./routes/Home"));
const Question = lazy(() => import("./routes/Question"));
const Answer = lazy(() => import("./routes/Answer"));
const Popup = lazy(() => import("./routes/Popup"));
const Modal = lazy(() => import("../src/modals/modal"));
const Result = lazy(() => import("../src/routes/Result"));
const Detailed = lazy(() => import("../src/routes/Detailed"));
const Test = lazy(() => import("../src/routes/Test"));
const New = lazy(() => import("../src/routes/New"));
const DeleteMember = lazy(() => import("../src/routes/DeleteMember"));
const PlaceDetail = lazy(() => import("../src/routes/PlaceDetail"));
const AdminFilter = lazy(() => import("./routes/Admin/AdminFilter"));
const AdminReqPlace = lazy(() => import("./routes/Admin/AdminReqPlace"));
const AddDestinationMap = lazy(() => import("../src/routes/AddDestinationMap"));
const ScheduleView = lazy(() => import("../src/routes/ScheduleView"));
const WorldCupSelect = lazy(() => import("./routes/WorldCupSelect"));
const WorldCupSelectResult = lazy(() =>
    import("./routes/WorldCupSelectResult")
);
const PlaceDetailImage = lazy(() => import("../src/routes/PlaceDetailImage"));
const BalanceGameSelect = lazy(() => import("./routes/BalanceGameSelect"));
const BalanceGameSelectResult = lazy(() =>
    import("./routes/BalanceGameSelectResult")
);
const WorldCupResultItemList = lazy(() =>
    import("./routes/WorldCupResultItemList")
);
const AddSchedulePlace = lazy(() => import("../src/routes/AddSchedulePlace"));
const BalanceMorePlace = lazy(() => import("../src/routes/BalanceMorePlace"));
const InsuranceGameSelect = lazy(() => import("./routes/InsuranceGameSelect"));
const InsuranceGameSelectResult = lazy(() =>
    import("./routes/InsuranceGameSelectResult")
);
const AdminActivityStatus = lazy(() =>
    import("./routes/Admin/AdminActivityStatus")
);
const DetailFeedView = lazy(() => import("./routes/DetailFeedView"));
const DetailNFT = lazy(() => import("./routes/DetailNFT"));
const AdminWork = lazy(() => import("./routes/Admin/AdminWork"));
const EmailCert = lazy(() => import("./routes/EmailCert"));
const DetailMyList = lazy(() => import("./routes/DetailMyList"));
const DetailFeed = lazy(() => import("./routes/DetailFeed"));
const DetailRecruitment = lazy(() => import("./routes/DetailRecruitment"));
const AdminContent = lazy(() => import("./routes/Admin/AdminContent"));
const AdminEnrollPlace = lazy(() => import("./routes/Admin/AdminEnrollPlace"));
const AdminMyGalleryShop = lazy(() =>
    import("./routes/Admin/AdminMyGalleryShop")
);
const AdminLogin = lazy(() => import("./routes/Admin/AdminLogin"));

const App = () => (
    <>
        <PhoneWidth>
            <Router>
                <Suspense
                    fallback={
                        <LoadingBox>
                            <ClipLoader color="#25B1FA" />
                        </LoadingBox>
                    }
                >
                    <Routes>
                        <Route path="/question" Component={Question} />
                        <Route path="/home" Component={Home} />
                        <Route path="/result" Component={Result} />
                        <Route path="/answer" Component={Answer} />
                        <Route path="/modal" Component={Modal} />
                        <Route path="/detailed" Component={Detailed} />
                        <Route path="/popup" Component={Popup} />
                        <Route path="/test" Component={Test} />
                        <Route path="/new" Component={New} />
                        <Route path="/delete-member" Component={DeleteMember} />
                        <Route
                            path="/placedetail/:contentId"
                            Component={PlaceDetail}
                        />
                        <Route
                            path="/add-destination-map"
                            Component={AddDestinationMap}
                        />
                        <Route path="/scheduleview" Component={ScheduleView} />
                        <Route
                            path="/worldcupselect"
                            Component={WorldCupSelect}
                        />
                        <Route
                            path="/worldcupselectresult"
                            Component={WorldCupSelectResult}
                        />
                        <Route
                            path="/balancegameselect"
                            Component={BalanceGameSelect}
                        />
                        <Route
                            path="/balancegameselectresult"
                            Component={BalanceGameSelectResult}
                        />
                        <Route
                            path="/balancemoreplace"
                            Component={BalanceMorePlace}
                        />
                        <Route
                            path="/worldcupresultitemlist"
                            Component={WorldCupResultItemList}
                        />
                        <Route
                            path="/add-schedule-place"
                            Component={AddSchedulePlace}
                        />
                        <Route
                            path="/insurancegameselect"
                            Component={InsuranceGameSelect}
                        />
                        <Route
                            path="/insurancegameselectresult"
                            Component={InsuranceGameSelectResult}
                        />
                        <Route
                            path="/detailfeedview"
                            Component={DetailFeedView}
                        />
                        <Route path="/detail-nft" Component={DetailNFT} />
                        <Route
                            path="/email-cert/:emailId/:authCode"
                            Component={EmailCert}
                        />
                        <Route path="/detailmylist" Component={DetailMyList} />
                        <Route path="/detail-feed" Component={DetailFeed} />
                        <Route
                            path="/detail-recruitment"
                            Component={DetailRecruitment}
                        />
                        <Route 
                            path="/placedetailimage" 
                            Component={PlaceDetailImage} />
                    </Routes>
                </Suspense>
            </Router>
        </PhoneWidth>
        <PcWidth>
            <Router>
                <Suspense
                    fallback={
                        <LoadingBox>
                            <ClipLoader color="#25B1FA" />
                        </LoadingBox>
                    }
                >
                    <Routes>
                        <Route path="/admin-filter" Component={AdminFilter} />
                        <Route
                            path="/admin-reqplace"
                            Component={AdminReqPlace}
                        />
                        <Route
                            path="/admin-activitystatus"
                            Component={AdminActivityStatus}
                        />
                        <Route path="/admin-work" Component={AdminWork} />
                        <Route path="/admin-content" Component={AdminContent} />
                        <Route
                            path="/admin-enrollplace"
                            Component={AdminEnrollPlace}
                        />
                        <Route
                            path="admin-mygalleryshop"
                            Component={AdminMyGalleryShop}
                        />
                        <Route path="admin-login" Component={AdminLogin} />
                    </Routes>
                </Suspense>
            </Router>
        </PcWidth>
    </>
);
const LoadingBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`;
const PhoneWidth = styled.div`
    width: 100%;
    /* height: 100%; */
    max-width: 450px;
    margin: 0 auto;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
`;
const PcWidth = styled.div`
    width: 100%;
    /* height: 100%; */
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
`;

export default App;
